<template>
    <section class="reset-password-section">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2 mt-5">
                <div class="col-md-5 col-12">
                    <h4 class="mb-3">Nueva Contraseña</h4>
                    <br>
                    <div>
                    <form class="form-login-reset-password" @submit.prevent="resetPassword">
                        <div class="form-group">
                        <label for="password" class="opensans-bold">Contraseña</label>
                        <input type="password" class="form-control" id="password" v-model="password" placeholder="Contraseña" required>
                        </div>
                        <div class="form-group">
                        <label for="password-confirm" class="opensans-bold">Confirmar Contraseña</label>
                        <input type="password" class="form-control" id="password-confirm" v-model="passwordConfirm" placeholder="Nueva Contraseña" required>
                        </div>
                        <div class="row">
                        <div class="col-5"></div>
                        </div>
                        <button type="submit" class="btn btn-primary btn-mccain mt-4">Enviar</button>
                        
                        <input type="hidden" id="token" v-model="token">
                        <input type="hidden" id="principal" v-model="this.principal">
                    </form>
                    </div>
                </div>
                <form v-if="this.token_expired" @submit.prevent="requestPassword">
                    <input v-model="this.principal" type="email" name="email" :placeholder="$t('form_email')" style="display: none;">
                    <button class="btn btn-primary mt-4" type="submit">Reenviar E-mail</button>
                </form>
            </div>
        </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>
  
  <script>
  import axios from 'axios';
  import { route } from "../../enviroment.js"
  
  export default {
    name: 'PasswordReset',
    i18n: {
         messages: {
             en: {
                 form_email: "Email",
                 form_forgot_password: "Forgot your password?",
                 form_forgot_password_text: "Don't worry, we will send you an email with the steps to restore it.",
                 success: "Your password has been restored",
                 empty_email: "Email cannot be empty.",
                 email_send: "An email was sent to you.",
                 email_send_success: "Success!"
             },
             es: {
                 form_email: "Correo electrónico",
                 form_forgot_password: "¿Olvidaste tu contraseña?",
                 form_forgot_password_text: "No te preocupes, te enviaremos un correo con instrucciones para resetearla.",
                 success: "Su contraseña ha sido reestablecida",
                 empty_email: "Por favor, complete el campo Correo electrónico.",
                 email_send: "Se le envió un email.",
                 email_send_success: "Éxito!"
             }
         }
     },
    data() {
      return {
        password: '',
        passwordConfirm: '',
        token: '',
        principal: '',
        message: '',
        messageClass: '',
        token_expired: false
      };
    },
    mounted() {
        this.token = this.$route.query.token || '';
        this.principal = this.$route.query.principal || '';
    },
    methods: {
        showAlert(type, title, message) {
            this.$refs.alert.showAlert(
                type, // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                title, // Header of the alert
                { 
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                } 
            )
        },
        async resetPassword() {
            if (this.password !== this.passwordConfirm) {
                this.showAlert('warning', `${this.$t('caution')}!`, 'Las contraseñas no coinciden.');
                return;
            }
            axios.post(this.$woe_api_uri + '/reset-password', {
                password: this.password,
                passwordConfirm: this.passwordConfirm,
                token: this.token,
                principal: this.principal
            }).then(() => {
                this.showAlert('success',`${this.$t('success')}`, 'Contraseña restablecida con éxito.');
                setTimeout(() => {
                    location.href = "" + route + "";
                }, 3000);
            }).catch((error) => {
                this.showAlert('warning', `${this.$t('caution')}!`, 'Token inválido, por favor solcite uno nuevo.');
                if(error){
                    this.token_expired = true;
                }
            });
        },
        requestPassword(){
            if (!this.principal) {
                this.showAlert('warning', `${this.$t('caution')}!`, this.$t('empty_email'));
                return;
            }
             axios.post(this.$woe_api_uri + '/request-reset-password',
                 {
                     "principal":this.principal
                 }
             ).then(() => {
                 this.showAlert('success',`${this.$t('email_send_success')}`, this.$t('email_send'));
                 this.token_expired = false;
                 setTimeout(() => {
                    location.href = "" + route + "";
                }, 3000);
             })
             .catch((error) => {
                this.showAlert('warning', `${this.$t('caution')}!`, error.response.data.error.message);
             });
         }
    }
  };
  </script>
  
  <style scoped>
  .brixslab {
    font-family: 'Brix Slab', serif;
  }
  
  .opensans-bold {
    font-weight: bold;
  }
  
  .btn-mccain {
    background-color: #ffcc00;
    border-color: #ffcc00;
  }
  
  .alert-success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .alert-danger {
    background-color: #f8d7da;
    color: #721c24;
  }
  </style>
  