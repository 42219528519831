<template>
  <div class="pedido-listado">
    <section class="pedido-list">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="return d-block">
              <router-link :to="route + 'contacto'">
                <img src="assets/img/backspace.svg" class="mb-5">
              </router-link>
            </div>
            <div class="section-title">
              <h5 class="pull-left">{{ $t("text") }}</h5>
            </div>
          </div>
        </div>
        <div class="row" id="mostrarTablas">
          <div class="col-md-12">
            <div class="confirmado mt-2">
              <!--Para desktop-->
              <table class="bg-white d-none d-sm-table" id="pedidosTable">
                <thead>
                <tr>
                  <th>{{ $t("date") }}</th>
                  <th class="text-center">{{ $t("name") }}</th>
                  <th class="text-center">{{ $t("email") }}</th>
                  <th class="text-center">{{ $t("request") }}</th>
                  <th class="text-center">{{ $t("reason") }}</th>
                  <th class="text-center">{{ $t("po_number") }}</th>
                  <th class="text-center">{{ $t("invoice_number") }}</th>
                  <th class="text-center">{{ $t("description") }}</th>
                  <th class="text-center">{{ $t("status") }}</th>

                </tr>
                </thead>
                <tbody>
                <tr class="b-top" v-for="(caso,index) in this.cases.records" :key="index">
                  <td>{{ caso.CreatedDate }}</td>
                  <td class="text-center">{{ caso.SuppliedName }}</td>
                  <td class="text-center">{{ caso.SuppliedEmail }}</td>
                  <td class="text-center">{{ caso.Request__c }}</td>
                  <td class="text-center">{{ caso.Reason__c }}</td>
                  <td class="text-center">{{ caso.PO_Number__c }}</td>
                  <td class="text-center">{{ caso.Invoice_Number__c }}</td>
                  <td class="text-center">{{ caso.Description }}</td>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)" :class="'label-order-change-' + caso.Status">{{ caso.Status }}</a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>

              <!--Para mobile hay que hacer una tabla por pedido-->
              <table class="bg-white d-block d-sm-none pedidosTableMobile b-top"
                     v-for="(order_change,index) in this.order_changes" :key="index">
                <tr>
                  <th>{{ $t("date") }}</th>
                  <td>{{ order_change.creation_date }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("order_number") }}</th>
                  <td class="text-center">{{ order_change.order_number }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("type_request") }}</th>
                  <td class="text-center">{{ order_change.change_type }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("description") }}</th>
                  <td class="text-center">{{ order_change.description }}</td>
                </tr>
                <tr>
                  <th class="text-center">{{ $t("status") }}</th>
                  <td class="text-center">
                    <div class="inline-flex align-center label-content justify-center">
                      <a href="javascript:void(0)"
                         :class="'label-order-change-' + order_change.status">{{ order_change.status_name }}</a>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="loading"></div>
        <div class="row">
          <div class="col-md-12 text-center mb-5">
            <ul class="pagination">
              <li @click="filtrarPedidos('page',index)" v-for="index in this.total_paginas" :key="index">
                <span class="active" v-if="(index == this.filtro_page)">{{ index }}</span>
                <span v-else>{{ index }}</span>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
  </div>
</template>

<script>
import {route} from "../../enviroment.js"
import $ from 'jquery'
import authMixin from "@/mixins/authMixin";
import axiosInstance from "@/main";

export default {
  name: 'MisCasos',
  data: function () {
    return {
      cases: [],
      key: '',
      route: route,
      filtro_page: 1,
      total_paginas: '',
    }
  },
  i18n: {
    messages: {
      en: {
        text: "List of cases.",
        date: "Date",
        reason: "Reason",
        request: "Request",
        description: "Description",
        status: "Status",
        email: "Email",
        name: "Name",
        invoice_number: "Invoice Number",
        po_number: "PO Number",
      },
      es: {
        text: "Listado de los casos creados.",
        date: "Fecha",
        description: "Descripcion",
        status: "Estado",
        reason: "Motivo",
        request: "Solicitud",
        email: "Email",
        name: "Nombre",
        invoice_number: "Numero de Factura",
        po_number: "Numero PO",
      }
    }
  },
  mixins: [authMixin],
  async created() {
    const valid = await this.validateUserAndSoldTo();
    if (valid) {
      this.getCases();
    }
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    mostrarCarga() {
      $('.loading').removeClass('d-none');
      $('#mostrarTablas').addClass('d-none');
    },
    ocultarCarga() {
      $('.loading').addClass('d-none');
      $('#mostrarTablas').removeClass('d-none');
    },
    getCases() {
      axiosInstance.get(`${this.$woe_api_uri}/contact/cases`)
          .then((response) => {
            this.ocultarCarga()
            this.cases = response.data.data.cases
          })
          .catch((error) => {
            if (error.code == "ERR_BAD_REQUEST") {
              location.href = "/login";
            } else {
              this.showAlert('warning', `${this.$t("caution")}!`, error.response.data.error.message);
            }
          });
    },
  }
}
</script>