export default {
    methods: {
        validateUserAndSoldTo() {
            const user = JSON.parse(sessionStorage.getItem('pwa-woe-user-profile'));
            const sold_to = sessionStorage.getItem('pwa-woe-sold-to');

            if (user && user.user_type === 'person') {
                if (!sold_to || !JSON.parse(sold_to)) {
                    this.$router.push({ path: 'sold-tos' });
                    return false;
                }
            }
            return true;
        }
    }
};
