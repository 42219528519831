<template>
    <section class="password-section">
         <div class="container">
             <div class="row">
                 <div class="col-12">
                     <h4>{{ $t("form_forgot_password") }}</h4>
                     <p>{{ $t("form_forgot_password_text") }}</p>
                     <form @submit.prevent="requestPassword">
                         <input v-model="this.principal" type="email" name="email" :placeholder="$t('form_email')">
                         <button type="submit">{{ $t('buttons.send') }}</button>
                     </form>
                     <div class="volver">
                         <a :href="route + 'login'">
                             <span>
                                 <img src="assets/img/backspace.svg">
                                 {{ $t("buttons.back_to_home") }}
                             </span>
                         </a>
                     </div>
                 </div>
             </div>
         </div>
     </section>
     <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
 </template>
 <script>
 import axios from 'axios'
 import { route } from "../../enviroment.js"
 export default {
     name: "ForgotPassword",
     i18n: {
         messages: {
             en: {
                 form_email: "Email",
                 form_forgot_password: "Forgot your password?",
                 form_forgot_password_text: "Don't worry, we will send you an email with the steps to restore it.",
                 success: "Your password has been restored",
                 empty_email: "Email cannot be empty.",
                 email_send: "An email was sent to you.",
                 email_send_success: "Success!"
             },
             es: {
                 form_email: "Correo electrónico",
                 form_forgot_password: "¿Olvidaste tu contraseña?",
                 form_forgot_password_text: "No te preocupes, te enviaremos un correo con instrucciones para resetearla.",
                 success: "Su contraseña ha sido reestablecida",
                 empty_email: "Por favor, complete el campo Correo electrónico.",
                 email_send: "Se le envió un email.",
                 email_send_success: "Éxito!"
             }
         }
     },
     data() {
         return {
             route : route,
             principal : ''
         }
     },
     methods:{
         showAlert(type, title, message) {
             this.$refs.alert.showAlert(
                 type, // There are 4 types of alert: success, info, warning, error
                 message, // Message of the alert
                 title, // Header of the alert
                 { 
                     iconSize: 35, // Size of the icon (px)
                     iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                     position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                 } 
             )
         },
         requestPassword(){
            if (!this.principal) {
                this.showAlert('warning', `${this.$t('caution')}!`, this.$t('empty_email'));
                return;
            }
             axios.post(this.$woe_api_uri + '/request-reset-password',
                 {
                     "principal":this.principal
                 }
             ).then(() => {
                 this.showAlert('success',`${this.$t('email_send_success')}`, this.$t('email_send'));
                 setTimeout(() => {
                    location.href = "" + route + "";
                }, 3000);
             })
             .catch((error) => {
                this.showAlert('warning', `${this.$t('caution')}!`, error.response.data.error.message);
             });
         }
     }
 }
 </script>