<template>
   <section class="pedido-enviado" v-if="this.step == 4">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="return d-block d-sm-none">
                        <img src="assets/img/backspace.svg">
                    </div>
                    <div class="pasos">
                        <img src="assets/img/listo.png" class="d-none d-sm-block">
                        <img src="assets/img/listo_mobile.svg" class="w-100 d-block d-sm-none">
                    </div>
                    <span class="check"><img src="assets/img/check.svg"></span>
                    <div class="listo">
                        <h3>¡{{ $t("ready") }}!</h3>
                        <p>{{ $t("text") }} <b>#{{this.order_num}}</b> {{ $t("text_2") }}</p>
                        <button @click="returnToSoldto" class="btn-yellow"> {{ $t("buttons.ok") }} </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { route } from "../../enviroment.js"
import authMixin from '@/mixins/authMixin';
export default {
	name: "PedidoListo",
    i18n: {
        messages: {
            en: {
                ready: "Ready",
                text: "Your order",
                text_2: "has been sent to McCain for review and subsequent approval."
            },
            es: {
                ready: "Listo",
                text: "Tu pedido",
                text_2: "fue enviado a McCain para revisión y posterior aprobación.",
            }
        }
    },
	props: {
        step: String,
        order_num: String
	},
	data() {
		return {
            route : route,
		}
	},
    mixins: [authMixin],
    async created(){
        await this.validateUserAndSoldTo();
    },
    methods: {
        returnToSoldto() {
        const userType = sessionStorage.getItem('pwa-woe-user-type');
        
        if (JSON.parse(userType) === 'person') {
            location.href = 'sold-tos'
        } else {
            location.href = route;
        }
    }
    }
}
</script>