import "vue-multiselect/dist/vue-multiselect.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Modal from "vue-bs-modal";
import Datepicker from '@vuepic/vue-datepicker';
import VueBasicAlert from 'vue-basic-alert'
import '@vuepic/vue-datepicker/dist/main.css'
import VueCookies from 'vue-cookies'
import { createI18n } from 'vue-i18n'
import VueMultiselect from 'vue-multiselect'
import { Vue3BsPaginate } from 'vue3-bootstrap-paginate';
import axios from 'axios';

//i18n
import messages from './locales/common'
const language = window.$cookies.get('pwa-woe-language');
const i18n = createI18n({
    locale: (language === 'English') ? 'en' : 'es', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages
})

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(config => {
    const soldTo = sessionStorage.getItem('pwa-woe-sold-to');
    if (soldTo) {
      config.headers['Woe-Sold-To'] = JSON.parse(soldTo);
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

const app = createApp(App)
// SETUP ENVIRONMENT
// SERVER
app.config.globalProperties.$woe_api_uri = 'api2/es/api/v1';
// LOCAL
// app.config.globalProperties.$woe_api_uri = 'es/api/v1';

console.log('VERSION 1.2');

app
.use(router)
.use(Modal)
.use(VueCookies)
.use(i18n)
.component('VueMultiselect', VueMultiselect)
.component('Datepicker', Datepicker)
.component('VueBasicAlert', VueBasicAlert)
.component('Vue3BsPaginate', Vue3BsPaginate)
.mount('#app')

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('./sw.js');
    });
}

export default axiosInstance;