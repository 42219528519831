<template>
    <div class="modal-header">
        <h4 class="modal-title">{{ $t("title") }}</h4>
    </div>
    <div class="modal-body">
        <span class="d-flex justify-content-center align-items-center flex-column confirm-message"><i :class="icon"></i>
            <span class="text-left" v-html="$t('text')"></span>
        </span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="this.$emit('onClose')">
            {{ $t('buttons.cancel') }}
        </button>
        <button type="button" class="btn btn-primary" @click="this.$emit('onConfirm')">
            {{ $t('buttons.accept') }}
        </button>
    </div>
</template>
<script>
	export default {
		name: 'TermAndConditions',
        i18n: {
            messages: {
                en: {
                    title: "Terms & Conditions",
                    text: `
                        <h5>Accept our Terms & Conditions</h5>
                        <p>1. Lead time required for your orders is 8 weeks against ETA.</p>
                        <p>2. Orders will be shipped based on approved product supply capacity.</p>
                        <p>3. Forecast is not cumulative from month to month.</p>
                        <p>4. Backorders are not processed.</p>
                        <p>5. Orders will be shipped with thermographs, on pallets or slip sheets according to prior agreement.</p>
                        <p>6. "Pick up" orders (ExWorks incoterm) must be picked up on the stipulated date. We do not commit to having the product available if the appointment is scheduled before or after that date.</p>
                        <p>7. The logistics process will begin once the order is released from credit review.</p>
                        <p>8. Delivery dates are estimated and will depend on the availability of transportation companies.</p>
                        <p>9. Request for changes to orders are not accepted within two weeks or less before loading.</p>
                        <p>10. Any request will be evaluated and if information or changes are required, your Service Specialist will contact you.</p>
                        <p>11. It is the customer's responsibility to respond within 24 to 48 hours depending on the request.</p>
                    `
                },
                es: {
                    title: "Terminos & Condiciones",
                    text: `
                        <h5>Aceptar nuestros términos & Condiciones</h5>
                        <p>1. Lead Time requerido para sus órdenes es de 8 semanas contra ETA.</p>
                        <p>2. Ordenes serán despachadas en base a la capacidad de suplencia aprobada por producto.</p>
                        <p>3. Forecast no es acumulativo de mes a mes.</p>
                        <p>4. No se trabaja backorders.</p>
                        <p>5. Ordenes se enviarán con termógrafos, en paletas o “slip sheets” según previo acuerdo.</p>
                        <p>6. Las órdenes “pick up” (incoterm ExWorks) deberán ser retiradas   en la fecha estipulada. No nos comprometemos a tener el producto disponible si agendan cita antes o después de dicha fecha.</p>
                        <p>7. El proceso logístico comenzará una vez la orden sea liberado de la revisión de crédito.</p>
                        <p>8. Fecha de entregas son estimadas y dependerán de la disponibilidad de las compañías de transportación.</p>
                        <p>9. Solicitud de cambio en ordenes no son aceptados con dos semanas o menos antes de la carga.</p>
                        <p>10. Cualquier solicitud será evaluada y de requerir información o cambios su Especialista de Servicio se comunicará con usted.</p>
                        <p>11. La responsabilidad del cliente es de responder en un periodo de 24 a 48hr según la solicitud.</p>
                    `,

                }
            }
        }
    }
</script>