<template>
   <footer class="copyright bg-black">
		<div class="text-center">
			<p class="text-center">Copyright © 2015. McCain. {{ $t("all_right_reserved") }}. <a :href="$t('mccain_privacy_policy_link')" target="blank">{{ $t("global_privacy_policy") }}</a> v5.0</p>
		</div>
	</footer>
</template>
<script>
export default {
	name: "FooterCopyright",
	i18n: {
		messages: {
			en: {
				all_right_reserved: "All right reserved.",
				global_privacy_policy: "Global Privacy Policy"
			},
			es: {
				all_right_reserved: "Todos los derechos reservados.",
				global_privacy_policy: "Política de privacidad global"
			}
		}
	},
	data() {
		return {
		}
	},
}
</script>