<template>
    <section class="pedido-steps contact-form">
         <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="return d-block d-sm-none">
                        <router-link :to="route + 'change-order-list'">
                            <img src="assets/img/backspace.svg" class="mb-5">
                        </router-link>
                    </div>
                    <div class="section-title">
                        <h5 class="pull-left">{{ $t("title") }}</h5>
                        <router-link :to="route + 'change-order-list'">
                            <img class="d-none d-sm-block" src="assets/img/backspace.svg">
                        </router-link>
                    </div>
                </div>
            </div>
            <form>
                <div class="row mt-5">
                    <div class="col-md-3"></div>
                    <div class="col-md-3">
                        <div class="form-group" id="input-change_type">
                            <label>{{ $t("type_request") }}</label>
                            <select name="change_type_id" v-model="data.change_type_id">
                                <option v-for="(requestType,index) in this.requestTypes" :key="index" :value="requestType.code">{{requestType.value}}</option>
                            </select>
                            <span class="required-span d-none">{{ $t("type_request_mandatory") }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group" id="input-order-number">
                             <label>{{ $t("order_number") }}</label>
                             <input type="text" name="order_number" v-model="data.order_number">
                             <span class="required-span d-none">{{ $t("order_number_mandatory") }}</span>
                         </div>
                     </div>
                </div>
              <div class="row mt-3">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <div class="form-group" id="input-description">
                    <label>{{ $t("description") }}</label>
                    <textarea type="text" name="description" rows="6" v-model="data.description"></textarea>
                    <span class="required-span d-none">{{ $t("description_mandatory") }}</span>
                  </div>
                </div>
              </div>
              <div class="row mt-3"></div>
              <div class="row mt-5">
                <div class="col-md-3"></div>
                <a href="javascript:void(0)" @click="sendForm()" class="btn-green mr-0 ml-2">{{
                    $t("buttons.send")
                  }}</a>
              </div>
            </form>
         </div>
    </section>
  <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert"/>
</template>
<script>
import {route} from "../../enviroment.js"
import $ from 'jquery'
// import axios from 'axios'
import axiosInstance from '../../main';
import authMixin from '@/mixins/authMixin';

export default {
  name: "PedidosCambiosNuevo",
  data() {
    return {
      route: route,
      data: {
        change_type_id: '',
        order_number: '',
        description: ''
      },
      requestTypes: []
    }
  },
  i18n: {
    messages: {
      en: {
        title: "Order Change Request",
        type_request: "Request Type *",
        type_request_mandatory: "Request type is mandatory",
        order_number: "Order Number *",
        order_number_mandatory: "Order number is mandatory",
                    description: "Description *",
                    description_mandatory: "Description is mandatory",
                    form_succesfully_submitted: "The form has been successfully submitted"
                },
                es: {
                  title: "Solicitud de Cambios de ordenes",
                  type_request: "Tipo de solicitud *",
                  type_request_mandatory: "El tipo de solicitud es obligatorio",
                  order_number: "Numero de Orden *",
                  order_number_mandatory: "El numero de orden es obligatorio",
                  description: "Descripcion *",
                  description_mandatory: "La descripcion es obligatoria",
                  form_succesfully_submitted: "El formulario se envio correctamente"
                }
    }
  },
  mixins: [authMixin],
  async created() {
    const valid = await this.validateUserAndSoldTo();
    if (valid) {
      this.getRequestChangeTypes();
    }
  },
  methods: {
    showAlert(type, title, message) {
      this.$refs.alert.showAlert(
          type, // There are 4 types of alert: success, info, warning, error
          message, // Message of the alert
          title, // Header of the alert
          {
            iconSize: 35, // Size of the icon (px)
            iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
            position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right'
          }
      )
    },
    getRequestChangeTypes() {
      axiosInstance.get(this.$woe_api_uri + '/order-change/types', {})
          .then((response) => {
            this.requestTypes = (response.data.data.change_types);
          }).catch((error) => {
        this.showAlert('warning', `${this.$t('caution')}!`, error.response.data.error.message);
      });
    },
            validateForm(){
                let errors = 0;

                $('#input-change_type .required-span').addClass('d-none');
                $('#input-order-number .required-span').addClass('d-none');
                $('#input-description .required-span').addClass('d-none');

                //Valido change_type_id
                if (this.data.change_type_id == ""){
                    $('#input-change_type .required-span').removeClass('d-none')
                    $('#input-change_type select').addClass('required')
                    errors++;
                } else {
                    $('#input-change_type .required-span').addClass('d-none')
                    $('#input-change_type select').removeClass('required')
                }

                //Valido order_number
                if (this.data.order_number == ""){
                    $('#input-order-number .required-span').removeClass('d-none')
                    $('#input-order-number input').addClass('required')
                    errors++;
                } else {
                    $('#input-order-number .required-span').addClass('d-none')
                    $('#input-order-number input').removeClass('required')
                }

                //Valido input-description
                if (this.data.description == ""){
                    $('#input-description .required-span').removeClass('d-none')
                    $('#input-description textarea').addClass('required')
                    errors++;
                } else {
                    $('#input-description .required-span').addClass('d-none')
                    $('#input-description textarea').removeClass('required')
                }

                if (errors>0) {
                    return false;
                }

                return true;
            },
            sendForm(){
                if (!this.validateForm()) {
                    return false;
                }

                axiosInstance.post(this.$woe_api_uri + '/order-change', this.data)
                .then(() => {
                    this.showAlert('success',`${this.$t('success')}!`, this.$t('form_succesfully_submitted'));
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t('caution')}!`, error?.response?.data?.error?.message ?? this.$t('unexpected_error'));
                });
            }
        }
    }
</script>