<template>
    <main-banner :banners="banners"></main-banner>
        <section class="sold-tos-section">
            <div class="container">
            <label>Seleccione Sold To</label>
            <VueMultiselect
                v-model="selectedSoldTo"
                :options="formattedSoldTos"
                :close-on-select="true"
                :clear-on-select="false"
                :internal-search="true"
                track-by="sold_to"
                label="displayName"
                :searchable="true"
                :select-label="'Select'"
                :selected-label="'Selected'"
                :deselect-label="'Deselect'"
                :allow-empty="true"
                class="custom-select-soldtos"
            />
            </div>
            <br>
            <div class="button-container">
                <a href="javascript:void(0)" @click="updateSystemId" class="btn-green">Continuar</a>
            </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>
  
  
  <script>
  import MainBanner from "../layouts/MainBanner.vue"
  import axios from 'axios'
  import {route} from "@/enviroment";
  
  export default {
    name: 'SoldTos',
    components: {
      MainBanner
    },
    data() {
      return {
        sold_tos_by_vendor: [],
        selectedSoldTo: null,
        systemid: null
      };
    },
    created(){
        this.getSoldTosByVendor()
    },
    computed: {
        formattedSoldTos() {
        return this.sold_tos_by_vendor.map(item => ({
            ...item,
            displayName: `${item.sold_to} - ${item.name} - ${item.address}`
        }));
        }
    },
    methods: {
        getSoldTosByVendor() {
            sessionStorage.removeItem('pwa-woe-sold-to');
            return axios.get(this.$woe_api_uri + '/sold-to/list')
                .then((response) => {
                    this.sold_tos_by_vendor = response.data.data.sold_tos_by_vendor;
                    return response.data.data.sold_tos_by_vendor;
                })
                .catch((error) => {
                    this.showAlert('warning',`${this.$t('caution')}!`, error.response.data.error.message);
                });
        },
        showAlert(type, title, message) {
            this.$refs.alert.showAlert(
                type, // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                title, // Header of the alert
                { 
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                } 
            )
        },
        updateSystemId() {
            if (!this.selectedSoldTo || this.selectedSoldTo.sold_to === ''){
                this.showAlert('warning', 'Atención!', 'Debe seleccionar un Sold To para continuar');
                return;
            }
            this.systemid = this.selectedSoldTo.sold_to;
            sessionStorage.setItem('pwa-woe-sold-to', JSON.stringify(this.systemid));
            location.href = route;
        }
    }
  }
  </script>
  <style scoped>
  .container {
    text-align: center;
  }
  
  .custom-select-soldtos {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .button-container {
    text-align: center;
  }
  
  </style>